import React from "react";
import { Container, Row, Col, Button } from "reactstrap";

function TextGrid() {
  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth > 1024
      ? true
      : false;
  return (
    <>
      <div
        className="section border-top border-bottom"
        style={{
          background: "#fbfbfb",
          alignItems: "center",
          padding: size ? "2rem 0" : "3rem 0",
        }}
      >
        <Container>
          <Row>
            <Col lg={12}>
              <img
                src={require(`assets/images/about.jpg`)}
                width="100%"
                alt=""
                // style={{ border: "15px solid #34368b" }}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12} className="text-dark">
              <h3 className="text-400">
                <br />
                <br />
                Moving beyond the hype, Artificial Intelligence and its
                applications are fast being implemented across organizations in
                the Middle East compared to all the other emerging technologies.
                <br />
                <br />
                The biggest push from government bodies and leaders across the
                region has resulted in a unilateral approach towards adapting
                technologies to create businesses, countries and economies of
                the future and AI has a very important role to play. This is
                evident from the fact that the AI industry in the region is
                expected to reach around $1.5 billion by 2025.
                <br />
                <br />
                The more digital and faster the world gets, the smaller the
                window of opportunity for businesses and governments to deliver
                their products and services to their end users. Artificial
                Intelligence has arrived as the answer to help augment this
                window of opportunity and help businesses turn more profitable
                and lower the costs at the same time. With the detailed insights
                that AI offers, organizations today have the ability to have a
                complete tab on their clients and the market and respond
                accordingly in real time.
                <br />
                <br />
                Just like how Khaleej Times has seen UAE transform into the city
                of wonders as its 1st English newspaper, ARTELLIGENCE FORUM has
                seen and facilitated the rise of AI in businesses and government
                bodies being the 1st summit on the topic in the region.
                <br />
                <br />
                From focusing around the awareness of the topic in the first
                edition of the forum to having a discussion on implementing an
                AI strategy for your organization, the forum has contributed
                immensely to the region’s efforts to push the boundaries of AI.
                With the current uncertainty spread across the world, the use of
                technologies such as AI is what businesses need and are looking
                at because they know, it is technology that will help sail
                through this, unlocking a new phase of growth. But for
                businesses who are still on the fence, are you brave enough to
                take this risk of doing nothing?
              </h3>
            </Col>

            <Col md={12} className="mx-auto mt-5 align-self-center text-center">
              <Button
                href="/sponsor/download-brochure"
                className="btn"
                color="primary"
                size="md"
              >
                <p className="m-0 text-700" style={{ color: "#fff" }}>
                  REQUEST AGENDA
                </p>
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
      ;
    </>
  );
}

export default TextGrid;
