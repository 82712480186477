import React from "react";
import {
  Container,
  Button,
  Row,
  Col,
  Card,
  CardBody,
  TabContent,
  TabPane,
} from "reactstrap";
import ScrollAnimation from "react-animate-on-scroll";

function Agenda() {
  function AgendaCard({ data }) {
    return data.map((d) => (
      <Col lg={12}>
        <ScrollAnimation
          animateIn="fadeInDown"
          animateOnce={true}
          duration={0.4}
        >
          <Card style={{ backgroundColor: "#010822" }}>
            <CardBody>
              <Container>
                <Row>
                  <Col lg={2} className="align-self-center">
                    <h2 className="text-500 text-white"> {d.time}</h2>
                  </Col>
                  <Col lg={10} className="align-self-center text-white">
                    <h2 className="text-400">{d.title}</h2>
                    {d.description && (
                      <h3
                        className="text-400"
                        dangerouslySetInnerHTML={{ __html: d.description }}
                      />
                    )}
                  </Col>
                </Row>
              </Container>
            </CardBody>
          </Card>
        </ScrollAnimation>
      </Col>
    ));
  }

  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
        }}
        className="section"
        data-parallax={true}
      >
        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md={12}>
                <h1 className="title mb-1 section-title text-center">
                  <b>Agenda At a Glance</b>
                </h1>
                <br />
              </Col>
              <Col md="12">
                <TabContent activeTab={"1"}>
                  <TabPane tabId="1">
                    <Container>
                      <Row>
                        <AgendaCard data={content1} />
                        <Col lg={12} className={"text-center"}>
                          <Button
                            className="px-3 py-2 rounded-0"
                            color="primary"
                            href="/register"
                          >
                            <p
                              className="m-0 text-700"
                              style={{ color: "#010822" }}
                            >
                              REGISTER NOW
                            </p>
                          </Button>
                        </Col>
                      </Row>
                    </Container>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Container>
        </ScrollAnimation>
      </div>
    </>
  );
}

const content1 = [
  {
    time: "08.45",
    title: "Registrations and coffee",
  },
  {
    time: "09.15",
    title: "Ministerial address",
    description: `A smart city for all. A citizen centric approach that is inclusive, 
    innovative and participatory`,
  },
  {
    time: "09.30",
    title: "Keynote address",
    description: `Shaping the future for a smarter, greener and more livable Dubai –
    the next steps`,
  },
  {
    time: "09.45",
    title: "Keynote address",
    description: `Saudi Arabia: NEOM – Enabling digital transition that places people, 
    livability & rights at the center`,
  },
  {
    time: "10.00",
    title: "Opening panel discussion",
    description: `
    What is tomorrow city?<br/>
    Updating smart city strategy for a post Covid world – using the crisis 
to drive positive change across city development and planning<br/>
<ul>
<li>Smart city industry outlook</li>
<li>Urban innovation and ecosystems</li>
<li>Building automation & intelligent grid control</li>
</ul>
    `,
  },
  {
    time: "10.30",
    title: "Speech",
    description: `3D AI city: From AI and computer vision to actionable insights for your city<br/>
    <ul>
<li>3d printing moves from the office in to the home</li>
<li>AI role in changing the landscape for better urban governance</li>
<li>Using AI to have evolved interactions with citizens and fast track the digital engagements</li>
    </ul>
    `,
  },
  {
    time: "10.45",
    title: "Speech",
    description: `Re-thinking the designs of our cities – recreating cities to deliver healthy, 
    green and automated systems`,
  },
  {
    time: "11.00",
    title: "Getting to know each other – One-to-one networking",
    description: ``,
  },
  {
    time: "11.10",
    title: "Coffee break",
    description: ``,
  },
  {
    time: "11.35",
    title: "Speech",
    description: `Developing innovative concepts for urban challenges in the field of clean 
    environment with open data`,
  },

  {
    time: "12.00",
    title: "Panel discussion",
    description: `Data driving innovations in cities <br/>
    <ul>
    <li>Analysis of big data to identify areas for transformation </li>
    <li>Predictive big data analytics to identify areas that are prone to crime</li>
    <li>Implement data driven road optimization</li>
    </ul>
    `,
  },
  {
    time: "12.30",
    title: "Case Study",
    description: `Saudi Arabia: NEOM – Enabling digital transition that places people, 
    livability & rights at the center`,
  },
  {
    time: "12.45",
    title: "Presentation",
    description: `Abu Dhabi: UAE National Cybersecurity Strategy launched to secure smart 
    cities`,
  },
  {
    time: "13.00",
    title: "Lunch",
    description: ``,
  },
  {
    time: "14.20",
    title: "Speech",
    description: `Mobility as a service optimization. Equilibrium of MaaS, designing MaaS
    around users & their needs`,
  },
  {
    time: "14.35",
    title: "Panel Discussion",
    description: `Urban Mobility for Smarter Cities<br/>
    <ul>
    <li>The future of mobility will be autonomous, shared and electric</li>
<li>Urban mobility and automated delivery services will be transformed by a growing suite of connected devices </li>
<li>Smart technologies, Smart parking and the internet of things (IoT) to drive urban mobility</li>
    </ul>
    `,
  },
  {
    time: "15.05",
    title: "Spotlight: A Sensory World",
    description: `The coronavirus pandemic has highlighted how the use of sensors is key –
    whether it’s using wearables to monitor distance from others, or a sensor 
    for contactless temperature measurement. As we adjust to this new 
    situation, sensors will continue to grow in importance. When you 
    consider that two thirds of the world’s population will be living in cities by 
    2050, urban spaces will need to be smarter about catering to human 
    needs. All-sensing infrastructure that can tap into peoples’ wishes is one 
    way to reach this goal. How can this be achieved?`,
  },
  {
    time: "15.20",
    title: "Closing Keynote",
    description: `How a smart city learns from the crisis – what have we learnt that we 
    never expected to`,
  },
  {
    time: "15.40",
    title: "Interactive session with audience ",
    description: ``,
  },
];

export default Agenda;
