export const speakers = [
  {
    name: "Ayesha Al Almutawa",
    title: "Head, Strategy and Excellence",
    company: "Dubai Corporation for Ambulance Services",
    image: "ayesha.jpg",
    companyImage: undefined,
    text: [
      `Dr. Ayesha Al Mutawa is a seasoned highly skilled professional, a Futurist, a positive change
    agent, and an advocate for innovation, sustainable success &excellence in performance.
    Dr. Al Mutawa has more than 17 years of experience as a leader in Strategic Planning &
    Performance Management, Innovation &Design thinking, Quality &Business excellence,
    Sustainability &CSR.
    Dr. Al Mutawa worked for many prominent Federal government organizations &Local
    government authorities such as UAE Ministry of Health, Abu Dhabi Health Authority, and
    Dubai Healthcare City Authority, in the capacity of; Chief Innovation Officer, Director of
    Strategy and Future, Vice President Planning &Quality, Chair of Corporate Social
    Responsibility Programs and other leading roles.
    Currently she is working as Director of Strategy and Excellence at Dubai Corporation for
    ambulance services leading institutional development and innovation.
    Dr. Al Mutawa is a prominent speaker in Conferences and academic settings, she provides
    advisory services to government and private sectors’ organizations in the areas of Innovation
    management, Future shaping, change management, Innovation incubators, labs, platforms
    and other progressive themes such as AI strategy, Youth Strategy, Sustainability &CSR, and
    Happiness &wellbeing initiatives in line with clear benchmark and evidence based best
    practice.`,
    ],
    uae: true,
  },

  {
    name: "Eng. Saif Salem Bamadhaf",
    title: "Director of IT and Smart Government Services",
    company: "Department of Energy, Abu Dhabi",
    image: "saif.jpg",
    companyImage: undefined,
    text: [
      `Mr. Saif started his career as a network engineer at ADNOC HQ in Abu Dhabi in 1995 and since then has
    lead a career in Information & Communication Technology to become the head of IT Dept. in ADNOC
    HQ. He later joined Higher Colleges of Technology as the Chief Technology Officer, 4 years later he was
    appointed as Chairman Advisor to the Department of Municipalities and Transport. Currently he is the
    digital lead in Abu Dhabi Government.`,
    ],
    uae: true,
  },
  {
    name: "Hussain Al Fardan",
    title: "Head of Innovation Center & Innovation Oasis",
    company: "Dubai Customs",
    image: "hussain.png",
    companyImage: undefined,
    text: [
      `Hussain Al Fardan is the Head of Innovation Centre – Strategy and Corporate Excellence at
    Dubai Customs. Hussain has over 20 years of experience in employee engagement, Innovation
    and Business Improvement with proven interpersonal and leadership skills. He is experienced in
    all aspects of Innovation both internal and external within the organization, with demonstrable
    success in Building Innovation Culture within a Governmental environment. Hussain has
    developed and delivered innovation culture method that is now used by many government
    departments across the UAE to develop employee driven creativity and manage continuous
    improvement. Hussain is also the Head of Innovation Center in Dubai Customs and the
    Chairman of Ideas Arabia and Ideas Emirates.`,
    ],
    uae: true,
  },
  {
    name: "Maher Sulaiman Al Maskari",
    company: "Oman National Transport Company – MWASALAT",
    title: "Head of IT – Infrastructure and Support",
    image: "maher.jpg",
    companyImage: undefined,
    text: [
      `Maher Al Maskari is an Information Technology professional with over 17 years of experience in most IT
    divisions in both government & private sectors. He has excellent management skills & rich experience
    and involvement in IT Infrastructure projects. He is a strong believer of Digital transformation
    management driving.`,
    ],
    uae: true,
  },

  {
    name: "Sheeba Hasnain",
    title: "Senior IT and Digital Transformation Specialist",
    company: "",
    image: "sheeba.jpg",
    companyImage: undefined,
    text: [`Sheeba Hasnain received a prestigious award of “The CISO Award 2021“ for her
    contribution in IT, Digital Transformation, and Security areas. Her interest lies in learning
    and innovating with the pace of technology, in Emerging Technology(AI, IoT, ML,
    Blockchain, Cloud Computing) domain, ITS( Intelligent Transportation and Traffic Systems),
    Project Management and Business Execution and Consultation. She believes a highly
    influencing transformational style of leadership can obtain the best Strategic IT-Business
    Alignment to attain the Strategic Goals of the Organization (for any vertical). Her research
    interest lies in the usage of cutting-edge technologies to run a business with high pace
    technological growth. And presently, she has worked on a research paper &quot;Future Mobility
    in Smart Cities by 2030&quot;.`],
    uae: true,
  },
];

export const sponsors = [
  {
    name: "CME",
    pageLink: "cme",
    type: "NETWORKING SPONSOR",
    image: "cme.png",
    text: [
      `We are a multinational technology consulting firm. We have helped over 100 clients worldwide,
    including top US Fortune 500 companies, to become and remain leaders in their fields.
    We provide premium end-to-end tailor-made solutions and technology services across different
    disciplines, including Staff Augmentation, Custom Software Development, Smart Devices
    Engineering, Customer Experience, Internet of Things, Artificial Intelligence, Data Management, and
    Process Automation.
    We accelerate the digital transformation for businesses and corporations by developing scalable and
    forward-thinking projects to achieve operational excellence, improve customer engagement, and
    unlock new growth opportunities. Our proven track record of success spans across several industries,
    including Consumer Goods, Food, Healthcare, Hospitality, Insurance, Market Research, Retail,
    Telecom, and Utilities.
    Our teams of highly skilled engineers, creative thinkers, and industry-specific experts from 5
    locations across the globe delivered more than 250 innovative projects. To date, we served 50M+
    users and contributed to 7 US Patents.
    If you believe in the transformative power of technology: join us in Reimagining Everything!`,
    ],
    link: "https://www.gotocme.com",
    size: "80%",
    grid: 4,
  },
  {
    name: "SAS",
    pageLink: "sas",
    type: "AI & ANALYTICS PARTNER",
    image: "sas.jpg",
    text: [
      `SAS is the leader in analytics. Through innovative software and services, SAS empowers and inspires customers around the world to transform data into intelligence. SAS gives you THE POWER TO KNOW®.

      `,
    ],
    link: "https://www.sas.com/me",
    size: "80%",
    grid: 4,
  },

  {
    name: "MOZN",
    pageLink: "Mozn",
    type: "ASSOCIATE SPONSOR",
    image: "mozn.png",
    text: [
      `Mozn is the leading advanced analytics solutions and product firm in the Middle East, unlocking the true
      potential of its analytics talent to deliver value globally.
      Mozn aims to achieve market leadership in building and deploying bespoke advanced analytics solutions
      in the Middle East, and develop data and analytics driven products in niche areas globally.`,
    ],
    link: "",
    size: "80%",
    grid: 4,
  },
];

const groupBy = (key) => (array) =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

const groupByType = groupBy("type");
export const sponsorsPage = groupByType(sponsors);

export const news = [
  {
    id: "Hr",
    title: "Feeling unwell and want the day off in Abu Dhabi? Go to HR robot",
    image: "1.png",
    link: "Feeling-unwell-and-want-the-day-off-in-Abu-Dhabi--Go-to-HR-robot",
  },
  {
    id: "Hyperloop",
    title:
      "Hyperloop systems in UAE, other countries now closer to reality: DP World",
    image: "2.png",
    link: "Hyperloop-systems-in-UAE",
  },
  {
    id: "Advancements",
    title: "The advancements in real world Artificial Intelligence",
    image: "3.png",
    link: "The-advancements-in-real-world-Artificial-Intelligence",
  },
  {
    id: "Bigdata",
    title: "AI and Big Tech should not have control over health",
    image: "4.PNG",
    link: "AI-and-Big-Tech-should-not-have-control-over-health",
  },
  {
    id: "Coop",
    title: "The GCC: A bright future",
    image: "5.PNG",
    link: "The-GCC-A-bright-future",
  },
  {
    id: "AIbots",
    title: "AI bots to boost care at Saudi German Hospital",
    image: "6.PNG",
    link: "AI-bots-to-boost-care-at-Saudi-German-Hospital",
  },
  {
    id: "Transform",
    title:
      "Digital transformation to drive recovery of GCC retail sector post-Covid-19",
    image: "7.PNG",
    link: "Digital-transformation",
  },
  {
    id: "Cyber",
    title: "Cyber security vital to Dubai's sustained progress: Sheikh Hamdan",
    image: "8.PNG",
    link: "Cyber-security-Sheikh-Hamdan",
  },
];
