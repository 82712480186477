// reactstrap components
import { Container, Col, Row, Button } from "reactstrap";

import Anime from "../Anime";
import React from "react";

function LandingPageHeader({ home }) {
  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth >= 1024
      ? true
      : false;

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 50 ||
        document.body.scrollTop > 50
      ) {
      } else if (
        document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300
      ) {
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${require("assets/images/background.jpg")})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          overflow: "hidden",
        }}
        className="page-header"
        data-parallax={true}
      >
        <Container style={{ paddingTop: size && "10vh" }}>
          <Row className="align-items-center">
            <Col md={7} xs={12} className={`${!size && "text-center"}`}>
              <img
                src={require(`assets/images/logo.png`)}
                style={{ maxWidth: "700px" }}
                width="100%"
                alt="Finnovex"
              />

              <Container>
                <Row>
                  <Col>
                    <h1
                      className="text-700 text-white text-center"
                      style={{ fontSize: size ? "1.25rem" : "1rem" }}
                    >
                      <i
                        className="fa fa-calendar"
                        style={{ color: "#01b0ff" }}
                        aria-hidden="true"
                      ></i>
                      <span className="ml-4">18th AUG 2021</span>

                      <i
                        className="fa fa-map-marker"
                        style={{ color: "#01b0ff", marginLeft: size && "20px" }}
                        aria-hidden="true"
                      ></i>
                      <span className="ml-2">HYBRID EVENT, DUBAI</span>
                    </h1>
                  </Col>
                </Row>
              </Container>

              <br />
            </Col>
          </Row>
          <Row>
            <Col md={8} xs={12} className="mb-1" style={{ zIndex: 1000 }}>
              <Container fluid>
                <Row className="justify-content-center">
                  <Col lg={4} xs={6} className={`px-${size ? 0 : 1}`}>
                    <Button
                      className="px-3 py-2 rounded-0"
                      color="primary"
                      style={{ width: "99%" }}
                      href="/register"
                    >
                      <p className="m-0 text-700" style={{ color: "#010822" }}>
                        REGISTER <br /> NOW
                      </p>
                    </Button>{" "}
                  </Col>
                  <Col lg={4} xs={6} className={`px-${size ? 0 : 1}`}>
                    <Button
                      className="px-3 py-2 rounded-0"
                      color="primary"
                      style={{ width: "99%" }}
                      href="/sponsor/download-brochure"
                    >
                      <p className="m-0 text-700" style={{ color: "#010822" }}>
                        DOWNLOAD <br />
                        BROCHURE
                      </p>
                    </Button>{" "}
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default LandingPageHeader;
