import React from "react";

import { Container, Row, Col, Card } from "reactstrap";
import ScrollAnimation from "react-animate-on-scroll";
// const size =
//   document.body.clientWidth >= 1024 ||
//   document.documentElement.clientWidth > 1024
//     ? true
//     : false;

const content = [
  {
    title:
      "Director General, Executive Directors, Head of IT, Head egovernment, Head of Procurement from Government and Public authorities",
    image: "8.png",
  },
  {
    title:
      "Head of Digital Services, Head of Parking/Roads, Head of IT – Smart Services from Local Municipalities, Urban Development and  Public Works",
    image: "2.png",
  },
  {
    title:
      "Head of Strategy and Planning, Head of Digital, Head of Projects from Semi-Government/Holding Companies ",
    image: "6.png",
  },
  {
    title:
      "Real Estate Developers, Project Owners of various Hospitals, Hotels, Shopping Malls, Airports & Ports and Factories",
    image: "9.png",
  },
  {
    title:
      "Founder/President/Chairperson from various International Associations",
    image: "1.png",
  },
  {
    title: "City, Transport Planners",
    image: "2.png",
  },
  {
    title: "DTS, ITS Specialist",
    image: "7.png",
  },
];

function KeyNotes() {
  return (
    <>
      <div className="section py-5" style={{ background: "#efefef" }}>
        <Container>
          <Row className="justify-content-center">
            <Col className="ml-auto mr-auto " md={12}>
              <h1 className="title mb-4 section-title text-center text-dark">
                <b>Who Will You Meet</b>
              </h1>
              <br />
            </Col>
            {content.map((data, index) => (
              <Col lg={4} key={index} className="py-2">
                <Card
                  className="mx-auto text-center"
                  style={{
                    background: "transparent",
                    // boxShadow: "-8px 8px #000",
                  }}
                >
                  <img
                    src={require(`assets/images/icons/${data.image}`)}
                    alt=""
                    width="100%"
                    style={{ maxWidth: "100px" }}
                    className="mx-auto py-2"
                  />
                </Card>
                <h3 className="text-500 text-center text-dark">{data.title}</h3>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default KeyNotes;
