import React from "react";

import { Container, Row, Col, Card } from "reactstrap";

import ScrollAnimation from "react-animate-on-scroll";
const size =
  document.body.clientWidth >= 1024 ||
  document.documentElement.clientWidth > 1024
    ? true
    : false;

function WhoAttend() {
  return (
    <>
      <div
        style={{
          backgroundColor: "#472e93",
        }}
        className="section section-with-space border-top border-bottom py-0 pb-3"
        data-parallax={true}
      >
        <Container className="py-4">
          <Row>
            <Col lg={12}>
              <h1 className="section-title text-center p-2 text-white">
                High Profile And Influential Delegates
              </h1>
            </Col>
          </Row>
          <Row className="justify-content-center">
            {content2.map((data, index) => (
              <Col lg={3} xs={12} key={index} className="py-2">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOnce={true}
                  duration={0.5}
                >
                  <Card
                    className="p-3"
                    style={{
                      height: size ? "100px" : "auto",
                      backgroundColor: data.color && "#ffc000",
                    }}
                  >
                    <h3 className="text-700 py-3">
                      <span
                        className="text-info pr-2"
                        style={{ fontSize: "20px", color: "#281672" }}
                      >
                        <i class="fa fa-id-badge" aria-hidden="true"></i>
                      </span>{" "}
                      {data.title}
                    </h3>
                  </Card>
                </ScrollAnimation>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

const content2 = [
  { title: "Chief Executive Officer", color: true },
  { title: "SVP Insurance" },
  { title: "Chief Operating Officer", color: true },
  { title: "Chief Innovation Officer" },
  { title: "Chief Digital Officer" },
  { title: "Chief Marketing Officer", color: true },
  { title: "Chief Information Officer" },
  { title: "Chief Data and Analytics Officer", color: true },
  { title: "Chief Underwriting Officer" },
  { title: "Chief Risk Officer", color: true },
];

export default WhoAttend;
