import React from "react";

import { Container, Row, Col, Card } from "reactstrap";

import Slider from "react-slick";

function Sponsor({ sponsors }) {
  const rows = [
    ["NETWORKING SPONSOR", "AI & ANALYTICS PARTNER", "ASSOCIATE SPONSOR"],
  ];
  const settings = {
    dots: true,
    autoplay: true,
    arrows: true,
    autoplaySpeed: 4000,
    slidesToShow: 4,
    slidesToScroll: 4,
    className: "p-10",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div
        style={{
          backgroundColor: "e0e4e5",
          // backgroundImage: "linear-gradient(315deg, #d4418e 0%, #0652c5 74%)",
        }}
        className="section section-with-space"
        data-parallax={true}
      >
        <Container>
          <Row>
            <Col lg={12}>
              <h1 className="text-center text-main section-title">
                <b>Sponsors & Partners</b>
              </h1>
            </Col>
          </Row>
          {rows.map((row) => (
            <Row>
              {row.map((sponsor) =>
                sponsors[sponsor].map((s) => (
                  // <Col lg={4} className={"text-center"}>

                  //   <img
                  //     src={require(`assets/sponsors/${s.image}`)}
                  //     alt={s.name}
                  //     width={s.size}
                  //     style={{ borderRadius: "0" }}
                  //   />
                  // </Col>
                  <Col lg={s.grid || 4} className={"ml-auto mr-auto pb-3"}>
                    <h3
                      className="text-dark text-400 text-uppercase text-center pb-3"
                      style={{ margin: 0, padding: 0 }}
                      dangerouslySetInnerHTML={{
                        __html: s.type,
                      }}
                    ></h3>
                    <a href={`/sponsors/${s.pageLink}`}>
                      <Card
                        className="mx-auto"
                        style={{ borderRadius: 0, maxWidth: s.size }}
                      >
                        <img
                          src={require(`assets/sponsors/${s.image}`)}
                          alt={s.name}
                          width="100%"
                        />
                      </Card>
                    </a>
                  </Col>
                ))
              )}
            </Row>
          ))}
        </Container>
      </div>
    </>
  );
}

export default Sponsor;
