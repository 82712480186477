import React from "react";
import Navbar from "components/Navbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import Footer from "components/Footer.js";
import TextGrid from "components/TextGrid.js";
import Agenda from "components/Agenda.js";
import WhoAttend from "components/WhoAttend.js";
import KeyTopics from "components/KeyTopics.js";
import SpeakerCard from "components/SpeakerCard.js";
import Sponsor from "components/Sponsor.js";
import { speakers, sponsorsPage } from "./content";
import WhyAttend from "components/WhyAttend";

function Home() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });

  return (
    <>
      <div id="outer-container">
        <Navbar home={true} />
        <section id="home" />
        <LandingPageHeader />
        <main id="page-wrap">
          <section id="about" />
          <TextGrid />
          <KeyTopics />
          <div className="main">
            <section id="speakers" />
            <SpeakerCard speakers={speakers} />
          </div>

          <section id="agenda" />
          <Agenda />
          <WhyAttend />
          {/* <Sponsor sponsors={sponsorsPage} /> */}

          <Footer />
        </main>
      </div>
    </>
  );
}

export default Home;
