import React from "react";

import { Container, Row, Col, Card, Button, CardImg } from "reactstrap";

import ScrollAnimation from "react-animate-on-scroll";

function WhyAttend() {
  return (
    <>
      <div
        style={{
          backgroundColor: "#010822",
        }}
        className="section section-with-space border-top border-bottom py-0 pb-3"
        data-parallax={true}
      >
        <Container className="py-4">
          <Row>
            <Col lg={12}>
              <h1 className="section-title text-center p-2 text-white">
                WHY ATTEND?
              </h1>
            </Col>
          </Row>
          <Row className="justify-content-center">
            {content3.map((data, index) => (
              <Col lg={6} xs={6} key={index} className="py-2">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOnce={true}
                  duration={0.5}
                >
                  <Card
                    className="p-3"
                    style={{
                      minHeight: "300px",
                      backgroundColor: "transparent",
                      border: "1px solid #fff"
                    }}
                  >
                    <CardImg
                      src={require(`assets/images/icons/${data.image}`)}
                      style={{ maxWidth: "100px" }}
                      className="mx-auto"
                    />
                    <hr />
                    <h3 className="text-700 pt-3 text-white">{data.title}</h3>
                  </Card>
                </ScrollAnimation>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

const content3 = [
  {
    title: `It will provide a valuable opportunity to connect with a community 
    of high-powered decision-makers from every part of the smart 
    cities ecosystem – government and public authorities, local 
    municipalities, head of projects from semi-government/holding 
    companies, real estate developers, city planners, key people from 
    various international associations.`,
    image: "17.png",
  },
  {
    title: `This intersection of cutting-edge innovations with civic technology 
    will enlighten you on the latest advancements, and disruptive 
    solutions in the smart cities horizon.`,
    image: "20.png",
  },
  {
    title: `You can engage with the various solution providers presenting 
    their latest offerings that will help you to overhaul your business 
    processes, and get in tune with the new-age demand.`,
    image: "18.png",
  },
  {
    title: `It will elaborately cover how businesses and governments are 
    developing solutions and capabilities to meet their future 
    objectives.`,
    image: "15.png",
  },
];

export default WhyAttend;
