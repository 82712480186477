import React from "react";
import Navbar from "components/Navbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";

import Footer from "components/Footer.js";
// import Sponsor from "components/Sponsor.js";
// import Testimonials from "components/Testimonials.js";

// import { sponsors } from "./content";
import { Container, Row, Col } from "reactstrap";

function ThankYou({
  answer,
  match: {
    params: { id },
  },
}) {
  return (
    <>
      <Navbar />
      <ProfilePageHeader title={`THANK YOU ${answer}`} bgColor="#fff" />
      <div className="section profile-content" style={{ padding: 0 }}>
        <Container>
          <Row>
            <Col lg={12} className="text-center p-5 m-5">
              <h1>
                {id === "REPORT" && <></>}
                {id === "BROCHURE" && (
                  <>
                    Thank you for your interest in Future of Smart Cities. The
                    event brochure has been sent to the email address provided,
                    please check your inbox. In case you do not receive the
                    brochure, please drop us a line: marketing@ktdigibank.com
                  </>
                )}
                {id === "REGISTER" && (
                  <>
                    Thank you for your interest in Future of Smart Cities. We’ll
                    be in touch with you for further details about the event.
                  </>
                )}
                {id === "SPONSOR" && (
                  <>
                    Thank you for your interest. A member of our team will be in
                    touch with you. Please feel free to reach out to us at
                    marketing@ktdigibank.com for any further queries.
                  </>
                )}
              </h1>
            </Col>
          </Row>
        </Container>
      </div>

      <Footer />
    </>
  );
}

export default ThankYou;
